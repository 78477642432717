import React from 'react';
import "../../App.css";

export default function AboutMe() {
    
    return <h1 className="ph-text f-25">
    And he gave the apostles, the prophets, the evangelists, the shepherds and teachers, to equip the saints for the work of ministry, for building up the body of Christ, until we all attain to the unity of the faith and of the knowledge of the Son of God, to mature manhood, to the measure of the stature of the fullness of Christ.
    <br></br>Ephesians 4:11–13
    </h1>;
    
    
}