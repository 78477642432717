import React from 'react';
import "../../App.css";

export default function Faith() {
    
    return <h1 className="ph-text f-25">“For by grace you have been saved through faith. And this is not your own doing; it is the gift of God, not a result of works, so that no one may boast. For we are his workmanship, created in Christ Jesus for good works, which God prepared beforehand, that we should walk in them.”

    —Ephesians 2:8 - 10</h1>
    
    
}




